import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation ,Pagination} from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./../resource/PhotoGallery.css";

export default function PhotoGallery() {

  const [photoPathList, setPhotoPahtList] = useState([]);
  
  useEffect (() => {
      const apiPhotoPathList = [];
      const setApiPhotoPathList = (photoPath, photoName) => {
      const apiPhotoPath = {
                photoPath: '',
                photoName: ''
              }
              apiPhotoPath.photoPath = photoPath;
              apiPhotoPath.photoName = photoName;

              apiPhotoPathList.push(apiPhotoPath);
      };
        var config = {
          method: 'post',
          url: process.env.REACT_APP_PHOTOPATH_API,
          headers: {
            // 'User-Agent': 'AtMakeLab/1.0.0 (https://www.atmakelab.com/)'  
          },
          body: {id: "get_event_schedule", password:"atmakelab_doll_0513"}
        };
        axios(config)
          .then(function (response) {

            if(response.data.galleryPhotoPathList == null) return;
    
            for (let i = 0; i < response.data.galleryPhotoPathList.length; i++) {
              const apiPhoto = response.data.galleryPhotoPathList[i];
        
              setApiPhotoPathList(apiPhoto ,'');
            }
            setPhotoPahtList(apiPhotoPathList);

        }).catch(function (error) {
          console.log(error);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

      const params = {
        slidesPerView: 4,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        touchRatio: 1.5,
        navigatio: true,
        modules: [Navigation, Pagination],
        loopedSlides: photoPathList.length,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          clickable: true,
        },
        className: "mySwiper"
      }

    return (
      <Swiper {...params}>
      {photoPathList.map(photoData => 
						<SwiperSlide>
              <a href={photoData.photoPath} data-lightbox="photo">
							  <img src={photoData.photoPath} alt="Photos"/>
              </a>
						</SwiperSlide>
        )}
      </Swiper>
  );
};