
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function Schedule() {

  const [eventScheduleList, setEventScheduleList] = useState([]);
  

    const setParticipation = (participationFlg) => {
      if (participationFlg) {
        return 'participation';
      } else {
        return /*'non_participation'*/'';
      }

    }

    useEffect (() => {
      const eventList = [];
      const eventCategoryMap = new Map([['0001', 'dollsparty'], ['0002', 'idoll'], ['0003', 'dollshow']]);
      const setEventData = (eventName, eventDate, participationFlg, eventCode, eventPlace, linkAddress) => {
      const eventData = {
                eventName: '',
                eventDate: '',
                eventPlace: '',
                participationFlg: 'false',
                eventCategory: '',
                displayClass: '',
                linkAddress: ''
              }
              eventData.eventName = eventName;
              eventData.eventDate = eventDate;
              eventData.eventPlace = eventPlace;
              eventData.eventCode = eventCode;
              eventData.displayClass = eventCategoryMap.get(eventCode) + ' ' + setParticipation(participationFlg)
              eventData.participationFlg = participationFlg;
              eventData.linkAddress = linkAddress;
      
              eventList.push(eventData);
      }
        var config = {
          method: 'post',
          url: process.env.REACT_APP_SCHEDULE_API,
          headers: {
            // 'User-Agent': 'AtMakeLab/1.0.0 (https://www.atmakelab.com/)'  
          },
          body: {id: "get_event_schedule", password:"atmakelab_doll_0513"}
        };
        axios(config)
          .then(function (response) {

            if(response.data.eventList == null) return;
    
            for (let i = 0; i < response.data.eventList.length; i++) {

              const apiEventName = response.data.eventList[i].eventName;
              const apiEventTimestapm = response.data.eventList[i].eventStartTimestamp;
        
              const apiEventYear = apiEventTimestapm.substring(0,4);
              const apiEventMonth = apiEventTimestapm.substring(5,7);
              const apiEventDay = apiEventTimestapm.substring(8,10);

              const apiEventCode = response.data.eventList[i].eventCode;
              const apiEventPlace = response.data.eventList[i].eventPlace;
              const apiParticipationFlg = response.data.eventList[i].participationFlg;
              const apiLinkAddress = response.data.eventList[i].sourceUrl;
        
              setEventData(apiEventName, apiEventYear+'-'+apiEventMonth+'-'+apiEventDay, apiParticipationFlg, apiEventCode, apiEventPlace, apiLinkAddress);
            }
            setEventScheduleList(eventList);

        }).catch(function (error) {
          console.log(error);
        });
      },[]);


    return (
      eventScheduleList.map(eventDetail => 
        <div class={eventDetail.displayClass}>
          <p>{eventDetail.eventName}</p>
          <p>{eventDetail.eventDate}</p>
          <p>{eventDetail.eventPlace}</p>
          <p><a href={eventDetail.linkAddress} target="blanc">Event Link</a></p>
        </div>
    )
  );
};