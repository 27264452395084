import React from "react";
import { Dimmer , Loader} from "semantic-ui-react";
import { WindmillSpinner} from "react-spinner-overlay";
import './../resource/Loading.css'

function Loading() {

    const params = {
        loading: true,
        size: 32,
        color: "gray"
    }

    const margin = {
        class: "loadingEf",
    }
    return (
        <div {...margin} >
            <div> 
                <WindmillSpinner {...params}/>
            </div>
            <div>
                < Dimmer inverted={true} active={true}>
                    < Loader content={"Loading..."}/>
                </ Dimmer>
            </div>
        </div>
    )
}

export default Loading;