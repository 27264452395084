
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import exportFunction from './../common/CommonUtil';
import Loading from "./Loading";

const boothItem = {
  itemPhotoUrl: '',
  itemLinkUrl: '',
  itemName: ''
};

export default function BoothItem() {

  const [boothItemDetail, setBoothItemDetail] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

    const setBoothItemData = (itemPhotoUrl, itemLinkUrl, itemName) => {
      boothItem.itemPhotoUrl = itemPhotoUrl;
      boothItem.itemLinkUrl = itemLinkUrl;
      boothItem.itemName = itemName;
    }

    useEffect (() => {
        var config = {
          method: 'post',
          url: process.env.REACT_APP_BOOTHITEM_API,
          headers: {
            // 'User-Agent': 'AtMakeLab/1.0.0 (https://www.atmakelab.com/)'  
          },
          body: {id: "get_event_schedule", password:"atmakelab_doll_0513"}
        };
        setIsLoading(true);
        axios(config)
          .then(function (response) {

            if (response.data.resultStatus === "9") {
              console.log('ERROR:RESULT_STATUS('+response.data.resultStatus+')');
              return
            }
            const apiItemPhotoUrl = response.data.boothItem.itemPhotoUrl;
            const apiItemLinkUrl = response.data.boothItem.itemLinkUrl;
            const apiItemName = response.data.boothItem.itemName;
            
            setBoothItemData(apiItemPhotoUrl, apiItemLinkUrl, apiItemName);
            setBoothItemDetail(boothItem);
            setIsLoading(false);
        }).catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
      },[]);

    const linkOpen = () => {
      exportFunction.openLinkOtherWindow(boothItemDetail.itemLinkUrl);
    }

    const style = {
      style: {"margin-top":"50px"}
    }
  
    return (
          <>
            { isLoading ? <div {...style} ><Loading/></div> : 
            <div onClick={linkOpen}>
              <img src={boothItemDetail.itemPhotoUrl} alt={boothItemDetail.itemName} target='_blank' href={boothItemDetail.itemLinkUrl} />
            </div>
            }
            <div>
              <a rel="noreferrer" target='_blank' href='https://atmakelab.booth.pm/'>Go Booth Site!</a>
              <br /><br />
              { isLoading ? <Loading/> : 
              <a rel="noreferrer" target='_blank' href={boothItemDetail.itemLinkUrl}>{boothItemDetail.itemName}</a>
              }
            </div>
          </>
    );
};