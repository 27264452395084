import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './../resource/Calendar.css';

const eventList = [];

export default function Calendar() {

  const [eventDisplayList, setEventDisplayList] = useState([]);

  const [today, setToday] = useState(new Date());
  const setEventData = (eventName, year, month, day) => {
    const eventData = {
      eventName: '',
      eventDate: null
    }
    eventData.eventName = eventName;
    eventData.eventDate = new Date(year, month, day);
    eventList.push(eventData);
  }
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    '1-January', '2-February', '3-March', '4-April', '5-May', '6-June',
    '7-July', '8-August', '9-September', '10-October', '11-November', '12-December'
  ];

  useEffect (() => {
   // if (eventList.length > 0 ) return; 
    var config = {
      method: 'post',
      url: process.env.REACT_APP_SCHEDULE_API,
      headers: {
        // 'User-Agent': 'AtMakeLab/1.0.0 (https://www.atmakelab.com/)'  
      },
      body: {id: "get_event_schedule", password:"atmakelab_doll_0513"}
    };
    axios(config)
      .then(function (response) {

        for (let i = 0; i < response.data.eventList.length; i++) {
          const apiEventName = response.data.eventList[i].eventName;
          const apiEventTimestapm = response.data.eventList[i].eventStartTimestamp;
    
          const apiEventYear = Number(apiEventTimestapm.substring(0,4));
          const apiEventMonth = Number(apiEventTimestapm.substring(5,7));
          const apiEventDay = Number(apiEventTimestapm.substring(8,10));
    
          setEventData(apiEventName, apiEventYear, apiEventMonth, apiEventDay);   
        }

        setEventDisplayList(eventList);
    }).catch(function (error) {
      console.log(error);
    });
  },eventList);

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  }

  const firstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  }

  const prevMonth = () => {
    const prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    setToday(prevMonth);
  }

  const nextMonth = () => {
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    setToday(nextMonth);
  }

  /**
   * 本日を判定してID発行
   * @param {*} day
   * @returns
   */
  const addTodayId = (day) => {
    let now = new Date();
    if (now.getDate() == day && today.getMonth() == now.getMonth() && today.getFullYear() == now.getFullYear()) {
      return 'calendar__today';
    }
  };

  /**
   * 日付けの記載があるセルを判定
   * @param {*} day
   * @returns
   */
  const addDayClass = (day) => {
    const now = new Date();
    if (now.getDate() == day && today.getMonth() == now.getMonth() && today.getFullYear() == now.getFullYear()) {
      return 'calendar__today calendar__date';
    } else if (day != null) {
      return 'calendar__date';
    }
  };

  /**
   * スケジュール表示追加
   * @param {*} day
   * @returns
   */
  const addEvent = (day) => {
    if (day == null) {
      return
    }
    const result = [];

    for (let i = 0; i < eventList.length; i++) {
      if (today.getFullYear() == eventList[i].eventDate.getFullYear() &&
        today.getMonth()+1 == eventList[i].eventDate.getMonth() &&
        day == eventList[i].eventDate.getDate()) {
        result.push(<div key={i}>{eventList[i].eventName}</div>)
      }
    }
    return result;
  }
  
  const getMonthDays = () => {
    const year = today.getFullYear();
    const month = today.getMonth();
    const days = daysInMonth(year, month);
    const firstDay = firstDayOfMonth(year, month);

    const monthDays = [];
    let day = 1;
    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDay) || day > days) {
          week.push(null);
        } else {
          week.push(day);
          day++;
        }
      }
      monthDays.push(week);
      if (day > days) {
        break;
      }
    }
    return monthDays;
  }

  const monthDays = getMonthDays();
  
  return (
    <div className="calendar">
      <div className="calendar__header">
        <a onClick={prevMonth}>Prev</a>
        <div>
          <h2>{months[today.getMonth()]}</h2>
          <span>{today.getFullYear()}</span>
        </div>
        <a onClick={nextMonth}>Next</a>
      </div>
      <table className="calendar__table">
        <thead>
          <tr>
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {monthDays.map((w) => (
            <tr key={w}>
              {w.map((d, index) => (
                <th key={index}>
                  <div id={addTodayId(d)} className={addDayClass(d)}>{d}</div>
                  <div>
                    {addEvent(d)}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
