import { isMobile } from "react-device-detect";

export default function X() {
    
    return (
      <div>
        {isMobile ? (
            <a class="twitter-timeline" 
            data-lang="ja" 
            data-width="1000" 
            data-height="2400"
            data-chrome="noheader nofooter noborders" 
            data-theme="light"
            href="https://twitter.com/LoveSherylForEv?ref_src=twsrc%5Etfw">Tweets by LoveSherylForEv</a>
      ) : (
        <a class="twitter-timeline" 
              data-lang="ja" 
              data-width="500" 
              data-height="2400"
              data-chrome="noheader nofooter noborders" 
              data-theme="light"
              href="https://twitter.com/LoveSherylForEv?ref_src=twsrc%5Etfw">Tweets by LoveSherylForEv</a>
      )}
      </div>
    );
  }