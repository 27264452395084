import React from 'react';
import ReactDOM from 'react-dom/client';
import PhotoGallery from './component/PhotoGallery';
import BoothItem from './component/BoothItem';
import Calendar from './component/Calendar';
import Schedule from './component/Schedule';
import X from './component/X';
import reportWebVitals from './reportWebVitals';
import { isMobile } from "react-device-detect";

const photoGallery = ReactDOM.createRoot(document.getElementById('photoGallery'));
const boothItem = ReactDOM.createRoot(document.getElementById('boothItem'));
const calendar = ReactDOM.createRoot(document.getElementById('calendar'));
const schedule = ReactDOM.createRoot(document.getElementById('schedule'));
const x = ReactDOM.createRoot(document.getElementById('xArea'));

photoGallery.render(
  // <React.StrictMode>
    <PhotoGallery />
  // </React.StrictMode>
);

boothItem.render(
  <React.StrictMode>
    <BoothItem />
  </React.StrictMode>
);

calendar.render(
  // <React.StrictMode>
    <Calendar isLoggedIn={!isMobile}/>
  // </React.StrictMode>
);

schedule.render(
  // <React.StrictMode>
    <Schedule />
  // </React.StrictMode>
);

x.render(
  <React.StrictMode>
    <X/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
